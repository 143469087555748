import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { useUserStore } from "@/store/modules/User/user";

export const useTableSettingsStore = defineStore("TableSettings", () => {
  const user = useUserStore();
  const listRowsPerPage = [10, 25, 50, 100];
  const gridRowsPerPage = [9, 21, 45, 99];

  const pageNumber = ref(1);
  const rowsPerPage = ref(listRowsPerPage[1]);
  const view = ref("list");
  const viewingFolders = ref(false);
  const viewingHistory = ref(false);
  const viewingFavorites = ref(false);
  const footerHeight = ref(0);

  const getRowsPerPageType = computed(() => {
    let rowValues: number[] = [];
    switch (view.value) {
      case "list":
        rowValues = listRowsPerPage;
        break;
      case "grid":
        rowValues = gridRowsPerPage;
        break;
    }
    return rowValues;
  });

  const getRowsPerPage = computed(() => {
    let rowValues = 0;
    if (user.isGridView && !viewingHistory.value) {
      rowValues = user.getUserSettings?.CardsPerPage ?? gridRowsPerPage[1];
      rowValues = gridRowsPerPage.indexOf(rowValues) === -1 ? gridRowsPerPage[1] : rowValues;
    } else {
      rowValues = user.getUserSettings?.RowsPerPage ?? listRowsPerPage[1];
      rowValues = listRowsPerPage.indexOf(rowValues) === -1 ? listRowsPerPage[1] : rowValues;
    }
    return rowValues;
  });

  function updateFooterHeight (height: number) {
    footerHeight.value = height;
  }

  function setTableView (newView: string) {
    view.value = newView;
  }

  function updateSelectedRowsPerPage (newRowsPerPage: number) {
    const currentItemIndex = (pageNumber.value - 1) * rowsPerPage.value;
    pageNumber.value = Math.floor(currentItemIndex / newRowsPerPage) + 1;
    rowsPerPage.value = newRowsPerPage;
  }

  function updatePageNumber (newPageNumber: number) {
    pageNumber.value = newPageNumber;
  }

  function updateFoldersView (isViewingFolders: boolean) {
    viewingFolders.value = isViewingFolders;
  }

  function updateHistoryView (isViewingHistory: boolean) {
    viewingHistory.value = isViewingHistory;
  }

  function updateFavoritesView (isViewingFavorites: boolean) {
    viewingFavorites.value = isViewingFavorites;
  }

  return {
    pageNumber,
    rowsPerPage,
    view,
    viewingFolders,
    viewingHistory,
    viewingFavorites,
    footerHeight,
    getRowsPerPageType,
    getRowsPerPage,
    updateFooterHeight,
    setTableView,
    updateSelectedRowsPerPage,
    updatePageNumber,
    updateFoldersView,
    updateHistoryView,
    updateFavoritesView,
    listRowsPerPage,
    gridRowsPerPage,
  };
});
